@import '../../../Styles/variables.scss';

.checkbox_input {
  width: auto !important;
}
.new_input_container {
  width: 100%;
  height: auto;
  .danger_label {
    color: #ff7875;
  }
  .input_medium {
    input {
      padding: 12px 16px;
      border-radius: 6px;
      border: 1px solid var(--blue-gray-300, #cbd5e1);
      background: var(--blue-gray-50, #f8fafc);
      color: var(--gray-600, #52525b);
      font-family: $primary-font;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      opacity: 0.6;

      &::placeholder {
        color: var(--gray-600, #52525b);
        font-family: $primary-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        opacity: 0.6;
      }
    }
    .error_message {
      color: #ff7875;
      font-family: $primary-font;
      font-size: 12px;
      font-weight: 400;
    }
  }
  .input {
    input {
      height: 60px;
      border-radius: 6px;
      border: 1px solid var(--blue-gray-300, #cbd5e1);
      background: var(--blue-gray-50, #f8fafc);
      color: var(--gray-600, #52525b);
      font-family: $primary-font;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      opacity: 0.6;
      padding: 7px 18px;

      &::placeholder {
        color: var(--gray-600, #52525b);
        font-family: $primary-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        opacity: 0.6;
      }
    }
    .error_message {
      color: #ff7875;
      font-family: $primary-font;
      font-size: 12px;
      font-weight: 400;
    }
  }
  .checkbox_input {
    input[type='checkbox'] {
      width: 19px;
      height: 19px;
      -webkit-appearance: none;
      appearance: none;
      background-color: var(--form-background);
      margin: 0;
      font: inherit;
      color: currentColor;
      border-radius: 5px;
      border: 1px solid var(--blue-gray-400, #94a3b8);
      display: grid;
      place-content: center;
    }

    input[type='checkbox']::before {
      content: '';
      width: 0.65em;
      height: 0.65em;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      transform: scale(0);
      transform-origin: bottom left;
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em var(--form-control-color);
      background-color: CanvasText;
    }

    input[type='checkbox']:checked::before {
      transform: scale(1);
    }

    input[type='checkbox']:disabled {
      --form-control-color: var(--form-control-disabled);
      color: var(--form-control-disabled);
      cursor: not-allowed;
    }
  }
  label {
    color: #18181b;
    font-family: $primary-font;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 0 0 0.5rem 0;
    display: block;
  }
}
