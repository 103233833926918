@import '../../Styles/variables.scss';

.main_register_container {
  width: 100%;
  height: 100%;
  h1 {
    color: $black_primary;
    font-family: $primary-font;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 62px;
    letter-spacing: -2px;
    margin: 0;
  }
  p {
    color: var(--gray-600, #52525b);
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 1rem 0;
  }
  .register_form_container {
    .input_container {
      display: flex;
      align-items: center;
      gap: 1.3rem;
      flex-direction: column;
    }
    .saved_and_account_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      padding: 1.3rem 0;
      .remember {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        span {
          color: #18181b;
          font-family: $primary-font;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          padding-bottom: 0.3rem;
        }
      }
      .have_and_account {
        span {
          color: var(--blue-gray-500, #64748b);
          font-family: $primary-font;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 21px;
          a {
            color: #2563eb;
            font-family: $primary-font;
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: 21px;
            text-decoration-line: underline;
            margin-left: 0.4rem;
          }
        }
      }
    }
    .button_container {
      width: 100%;
    }
  }
  .google_login_button {
    margin-top: 5rem;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.3rem;
    }
  }
}

@include media('mobile') {
  .main_register_container {
    .register_form_container {
      .saved_and_account_container {
        flex-direction: column;
        gap: 0;
        align-items: flex-start;
      }
    }
    .google_login_button {
      margin-top: 2rem;
    }
  }
}
