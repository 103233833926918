@import '../../../Styles/variables.scss';

.linkedin_modal {
  padding: 1.3rem 0;
  .header_details_container {
    border-top: 1px solid #e5e9eb;
    border-bottom: 1px solid #e5e9eb;
    padding: 0.5rem 0;
    .auto_container {
      max-width: 608px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;
      flex-wrap: wrap;
      padding: 0 1rem;
    }
    .user_details {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      img {
        width: 99px;
        height: 99px;
        border-radius: 6px;
      }
      h2 {
        color: #18181b;
        font-family: $primary-font;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
        margin: 0;
      }
    }
    .close_icon {
      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }
  .form_container {
    max-width: 608px;
    margin: auto;
    padding: 1.4rem 1rem 0 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem;

    .divider {
      margin-bottom: 1rem;
    }
    .button_container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.7rem;
    }
  }
}
@include media(mobile) {
  .linkedin_modal {
    .header_details_container {
      .user_details {
        gap: 1rem;
        img {
          width: 50px;
          height: 50px;
        }
        h2 {
          font-size: 30px;
          line-height: 40px;
        }
      }
    }
  }
}
