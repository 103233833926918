@import '../../../Styles/variables.scss';

.new_input_container {
  #select_container {
    box-shadow: none !important;
    padding: 6px 6px;
    border-radius: 6px;
    border: 1px solid var(--blue-gray-300, #cbd5e1);
    background: var(--blue-gray-50, #f8fafc);
    color: var(--gray-600, #52525b) !important;
    font-family: $primary-font !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    opacity: 0.6 !important;
    svg {
      fill: #000000;
    }
    span {
      display: none;
    }
  }
  #danger_select_container {
    padding: 6px 6px;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 8px;
    border-color: #ff4d4f !important;
    box-shadow: none !important;
    opacity: 0.6;
    &:hover {
      border-color: #ff4d4f !important;
      box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
      border-inline-end-width: 1px;
      outline: 0;
    }
    &:focus {
      border-color: #ff4d4f !important;
      box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
      border-inline-end-width: 1px;
      outline: 0;
    }
    &:active {
      border-color: #ff4d4f !important;
      box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
      border-inline-end-width: 1px;
      outline: 0;
    }
  }
}
