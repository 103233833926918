@import '../../../Styles/variables.scss';

.screen_load_container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.253);
  z-index: 100;
  backdrop-filter: blur(5px);
  .loading_state_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .waiting_info {
      margin: 1.5rem 0 0 0;
      color: $black;
      font-weight: 500;
    }
  }
}
