@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './variables.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $primary-font;
}

a {
  color: inherit;
  text-decoration: none;
}

.error_message {
  color: #ff7875;
  font-family: $primary-font;
  font-size: 12px;
  font-weight: 400;
}

.ant-btn {
  height: auto !important;
  white-space: normal !important;
}
