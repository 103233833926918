@import '../../../Styles/variables.scss';

.linkedin_modal {
  padding: 1.3rem 0;
  .header_details_container {
    .auto_container {
      max-width: 608px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 0.5rem;
      flex-wrap: wrap;
      padding: 0 1rem;
    }

    .close_icon {
      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }
  .form_container {
    max-width: 608px;
    margin: auto;
    padding: 1.4rem 1rem 0 1rem;

    .divider {
      margin-bottom: 1rem;
    }
    .button_container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.7rem;
    }
  }
}
@include media(mobile) {
  .linkedin_modal {
    .header_details_container {
      .user_details {
        gap: 1rem;
        img {
          width: 50px;
          height: 50px;
        }
        h2 {
          font-size: 30px;
          line-height: 40px;
        }
      }
    }
  }
}
