@import "../../Styles/variables.scss";

.main_forgot_passowrd_container {
  width: 100%;
  min-height: 100vh;
  background-color: $gray5;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  .forgot_password_container {
    width: 100%;
    height: auto;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
    max-width: 700px;
    margin: 2rem;
    padding: 2rem;

    .title_container {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;
      margin: 0 0 2rem 0;

      h2 {
        margin: 0;
        color: $black2;
        font-weight: 400;
      }

      h4 {
        margin: 1rem 0;
      }
    }

    .form_container {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      max-width: 90%;
      margin: auto;

      .input_container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      .button_container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 1rem 0 2rem 0;
      }
    }
  }
}

@include media(mobile) {
  .main_forgot_passowrd_container {
    .forgot_password_container {
      margin: 1rem;
      padding: 2rem 1rem;
      .form_container {
        max-width: 100%;
        .button_container {
          justify-content: flex-start;
          flex-wrap: wrap;
        }
      }
    }
  }
}
