@import '../../Styles/variables.scss';

.unauth_layout_container {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;

  .side_banner_container {
    width: 40%;
    height: 100%;
    background-color: #2563eb;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .shape_1 {
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .shape_2 {
      position: absolute;
      top: 0;
      left: 0;
    }
    .logo {
      padding: 2rem;
    }
  }
  .side_content_container {
    width: 60%;
    height: auto;
    max-width: 526px;
    margin: auto;
    padding: 2rem;
    overflow-y: scroll;
    max-height: 85%;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@include media('tablet') {
  .unauth_layout_container {
    flex-direction: column-reverse;
    .side_banner_container {
      display: none;
    }
    .side_content_container {
      width: auto;
      max-width: 100%;
    }
  }
}

@include media('mobile') {
  .unauth_layout_container {
    height: auto;
    .side_content_container {
      padding: 2rem 1rem;
    }
  }
}
