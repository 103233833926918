@import "../../Styles/variables.scss";

.email_sended_container {
  width: 100%;
  height: 100vh;
  .details_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .image_container {
      margin-bottom: 2rem;
      img {
        max-width: 100px;
      }
    }
    h3 {
      margin: 0 0 0.5rem 0;
      font-weight: 400;
    }
    p {
      margin: 0;
      opacity: 0.8;
    }
    a {
      text-decoration: underline;
      color: $blue;
      margin-top: 1rem;
    }
  }
}
