@import '../../Styles/variables.scss';

.select_container {
  .ant-select-arrow {
    margin-top: -7px;
  }
  .ant-select-selector {
    height: auto !important;
    padding: 3px 16px 3px 12px !important;
    background: var(--black-white-white, #fff) !important;
    .ant-select-selection-item {
      color: var(--dark-gray-dark-gray-2, #252c32);
      font-family: $secondary-font;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.084px;
    }
  }
}
.select_container_full {
  width: 100%;
}

.tag {
  user-select: none;
  border-radius: 10px;
  border: none;
  padding: 0.5rem 0.4rem;
  margin: 0;
  span {
    font-family: $primary-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.tag_blue {
  background: rgba(118, 202, 252, 0.2);
  span {
    color: #141e53;
  }
  svg {
    fill: #141e53;
  }
}

.tag_purple {
  background: rgba(82, 107, 196, 0.15);
  span {
    color: #141753;
  }
  svg {
    fill: #141753;
  }
}

.tag_yellow {
  background: #fef9c3;
  span {
    color: #713f12;
  }
  svg {
    fill: #713f12;
  }
}

.tag_green {
  background: #dcfce7;
  span {
    color: #14532d;
  }
  svg {
    fill: #14532d;
  }
}

.tags_input {
  border: none !important;
  font-family: $primary-font !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  box-shadow: none !important;
  width: auto !important;
}

.select_tags_input {
  .ant-select-selector {
    border: none !important;
    font-family: $primary-font !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px !important;
    box-shadow: none !important;
    width: auto !important;
  }
  .ant-select-selection-placeholder {
    font-family: $primary-font !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px !important;
  }
}

.input_blue {
  .ant-select-selector {
    color: #141e53 !important;
  }
  .ant-select-selection-placeholder {
    color: #8489a4 !important;
  }
  color: #141e53 !important;
  &::placeholder {
    color: #8489a4 !important;
  }
}

.input_purple {
  .ant-select-selector {
    color: #141753 !important;
  }
  .ant-select-selection-placeholder {
    color: #8489a4 !important;
  }
  color: #141753 !important;
  &::placeholder {
    color: #8489a4 !important;
  }
}

.input_yellow {
  .ant-select-selector {
    color: #713f12 !important;
  }
  .ant-select-selection-placeholder {
    color: #b39a83 !important;
  }
  color: #713f12;
  &::placeholder {
    color: #b39a83 !important;
  }
}

.input_green {
  .ant-select-selector {
    color: #14532d !important;
  }
  .ant-select-selection-placeholder {
    color: #8489a4 !important;
  }

  color: #14532d !important;
  &::placeholder {
    color: #8489a4 !important;
  }
}
