@import '../../Styles/variables.scss';

.tabsbar_contianer {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  min-height: 76px;
  padding: 0.5rem 2rem;
  border-bottom: 1px solid #e5e9eb;
  .item_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    .item {
      cursor: pointer;
      border-bottom: 1px solid #dde2e4;
      p {
        color: var(--dark-gray-dark-gray-2, #252c32);
        font-family: $primary-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: -0.096px;
        margin: 0;
        white-space: nowrap;
      }
    }
    .item_active {
      border-color: #252c32 !important;
      p {
        font-weight: 700 !important;
      }
    }
  }
}

@include media(mobile) {
  .tabsbar_contianer {
    padding: 1rem;
    .item_container {
      overflow-x: scroll;
    }
  }
}
