@import '../../../Styles/variables.scss';

.profile_setting_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 2.3rem 2rem;
  flex-wrap: wrap;
  .profile_details {
    display: flex;
    align-items: center;
    gap: 1.3rem;

    h2 {
      color: #18181b;
      font-family: $primary-font;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      margin: 0;
    }
  }
  button {
    span {
      margin-right: 0.4rem;
    }
  }
}

.main_campaigns_container {
  border-radius: 10px 10px 10px 10px;
  background: var(--color-3, #fff);
  margin-top: 1.3rem;
  .title_container {
    padding: 1.3rem 3.5rem;
    h6 {
      margin: 0;
      color: var(--gray-900, #18181b);
      font-family: $primary-font;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
    p {
      margin: 0;
      color: var(--gray-500, #71717a);
      font-family: $primary-font;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
  .campaign {
    display: flex;
    align-items: center;
    padding: 1.3rem 3.5rem;
    border-top: 1px solid #e5e9eb;
    gap: 1rem;
    &:hover {
      border-top: 1px solid var(--gray-200, #e4e4e7);
      border-bottom: 1px solid var(--gray-200, #e4e4e7);
      background: var(--color-3, #fff);
      box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
    }
    cursor: pointer;
    .title {
      width: 25%;
      h5 {
        margin: 0;
        color: #18181b;
        font-family: $primary-font;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }
    .status {
      width: 25%;

      .active {
        width: fit-content;
        height: 32px;
        border-radius: 40px;
        background: var(--green-100, #dcfce7);
        padding: 4px 16px;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .dot {
          width: 8px;
          height: 8px;
          background-color: #22c55e;
          border-radius: 50%;
        }
        span {
          color: var(--green-900, #14532d);
          font-family: $primary-font;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
      .paused {
        width: fit-content;
        height: 32px;
        border-radius: 40px;
        background: var(--yellow-100, #fef9c3);
        padding: 4px 16px;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .dot {
          width: 8px;
          height: 8px;
          background-color: #facc15;
          border-radius: 50%;
        }
        span {
          color: var(--yellow-900, #713f12);
          font-family: $primary-font;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
      .canceled {
        width: fit-content;
        height: 32px;
        border-radius: 40px;
        background: var(--red-100, #fee2e2);
        padding: 4px 16px;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .dot {
          width: 8px;
          height: 8px;
          background-color: #ef4444;
          border-radius: 50%;
        }
        span {
          color: var(--red-900, #7f1d1d);
          font-family: $primary-font;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }

    .card_info {
      width: 25%;
      p {
        margin: 0;
        color: var(--gray-900, #18181b);
        font-family: $primary-font;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        text-transform: capitalize;
      }
      span {
        color: var(--gray-500, #71717a);
        font-family: $primary-font;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
        text-transform: capitalize;
      }
    }
    .subscription {
      width: 25%;
      p {
        margin: 0;
        color: var(--gray-500, #71717a);
        font-family: $primary-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
      }
    }
  }
}

@include media(tablet) {
  .main_campaigns_container {
    .title_container {
      padding: 1rem 2rem;
    }
    .campaign {
      padding: 1.3rem 2rem;
    }
  }
}

@include media(mobile) {
  .profile_setting_container {
    padding: 2.3rem 1rem;
  }
  .main_campaigns_container {
    .title_container {
      padding: 1rem 1rem;
    }
    .campaign {
      padding: 1.3rem 1rem;
      flex-wrap: wrap;
      .title {
        width: 50%;
      }
      .status {
        width: 40%;
      }
      .card_info {
        width: 50%;
      }
      .subscription {
        width: 40%;
      }
    }
  }
}
