@import '../../../Styles/variables.scss';

.modal_container {
  width: 100%;
  height: 100%;
  .modal_header_container {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #e8ebf0;
    padding-bottom: 0.3rem;
    h4 {
      margin: 0 0 0.5rem 0;
      color: var(--gray-900, #18181b);
      font-family: $primary-font;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px;
    }
    span {
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.03rem;
      font-size: 16px;
      line-height: 18px;
      color: #839096;
    }
  }
  .modal_body_container {
    width: 100%;
    height: auto;
    padding: 1rem 0;
  }
  .modal_footer_container {
    .button_container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 1rem;
    }
  }
}

.loading_state {
  opacity: 0.5;
  cursor: not-allowed;
  .loading_check_state_red {
    color: red !important;
    font-weight: 600 !important;
  }
}

.ant-modal-mask {
  background-color: rgba(24, 24, 27, 0.2) !important;
  backdrop-filter: blur(10px) !important;
}

.ant-modal {
  width: 100% !important;
  max-width: 765px !important;
}

.ant-modal-content {
  margin: 0.5rem;
}
