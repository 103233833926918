@import '../../../Styles/variables.scss';

.new_input_container {
  width: 100%;
  height: auto;
  .danger_label {
    color: $red;
    color: #ff7875;
  }
  .input {
    .error_message {
      color: #ff7875;
      font-family: $primary-font;
      font-size: 12px;
      font-weight: 400;
    }

    input {
      color: var(--gray-600, #52525b) !important;
      font-family: $primary-font !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 24px !important;
    }
  }
  label {
    display: block;
    margin: 0 0 0.5rem 0;
    color: #18181b;
    font-family: $primary-font;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
