.radio_button_container {
  input[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: white;
    margin: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #2563eb;
    border-radius: 50%;
    display: grid;
    place-content: center;
  }

  input[type='radio']::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #2563eb;
    background-color: #2563eb;
  }

  input[type='radio']:checked::before {
    transform: scale(1);
  }
}
