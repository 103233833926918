@import '../../Styles/variables.scss';

.home_container {
  padding: 2rem;
  border-radius: 10px;
  background: var(--black-white-white, #fff);
  margin-top: 1.3rem;
  .button_container {
    margin-top: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

@include media(mobile) {
  .home_container {
    padding: 2rem 1rem;
  }
}

.profile_setting_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 1rem 2rem;
  flex-wrap: wrap;
  .profile_details {
    display: flex;
    align-items: center;
    gap: 1.3rem;
    img {
      width: 99px;
      height: 99px;
      border-radius: 6px;
    }
    h2 {
      color: #18181b;
      font-family: $primary-font;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      margin: 0;
    }
  }
  .setting_container {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    flex-wrap: wrap;
  }
}

@include media(tablet) {
  .profile_setting_container {
    .setting_container {
      margin-top: 1rem;
    }
  }
}

@include media(mobile) {
  .profile_setting_container {
    padding: 1rem 1rem;
    .profile_details {
      h2 {
        font-size: 30px;
        line-height: 40px;
      }
    }
  }
}
