@import '../../Styles/variables.scss';

.navbar_container {
  width: 100%;
  height: auto;
  background: var(--ffffff, #fff);
  box-shadow: 0px 1px 0px 0px rgba(18, 32, 59, 0.09);
  .nav_item {
    width: auto;
    min-height: 61px;
    max-width: 1280px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 0 2rem;
    flex-wrap: wrap;
    h3 {
      color: var(--gray-900, #18181b);
      font-family: $primary-font;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px;
      text-transform: uppercase;
      margin: 0;
    }
  }
}

@include media(mobile) {
  .navbar_container {
    .nav_item {
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: 0;
      padding: 0.5rem 2rem;
    }
  }
}
