@import '../../Styles/variables.scss';

.auth_layout_container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background: #f9fafb;
  min-height: 100vh;

  .main_container {
    max-width: 1280px;
    margin: auto;
    padding: 2rem;
  }
}

@include media('mobile') {
  .auth_layout_container {
    .main_container {
      padding: 2rem 1rem;
    }
  }
}
