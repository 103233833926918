.scroll_down_button {
  position: fixed;
  width: 100%;
  right: 40px;
  top: 40px;
  height: 20px;
  z-index: 1;
  cursor: pointer;
  color: green;
}
