@import '../../../Styles/variables.scss';

.spinner_container {
  svg {
    fill: $white;
  }
}

.primary {
  svg {
    fill: $blue;
  }
}

.danger {
  svg {
    fill: $red;
  }
}
