@import '../../Styles/variables.scss';

.setting_header_container {
  width: 100%;
  height: auto;
  border-radius: 10px;
  background: var(--black-white-white, #fff);
  padding-bottom: 1.5rem;

  .setting_main_content_container {
    border-bottom: 1px solid #dde2e4;
  }
}
