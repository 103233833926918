@import '../../../Styles/variables.scss';

.profile_setting_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 2.3rem 2rem;
  flex-wrap: wrap;
  .profile_details {
    display: flex;
    align-items: center;
    gap: 1.3rem;

    h2 {
      color: #18181b;
      font-family: $primary-font;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      margin: 0;
    }
  }
}

.main_linkedin_profiles_container {
  margin-top: 1.3rem;
  .profile_not_selected_container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 0 1rem;
    p {
      margin: 0;
      color: var(--dark-gray-dark-gray-2, #252c32);
      font-family: $secondary-font;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.12px;
    }
  }
  .linkedin_profiles_container {
    border-radius: 6px;
    background: var(--black-white-white, #fff);
    padding-bottom: 1.3rem;
    .header_details {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 1rem;
      h6 {
        color: var(--dark-gray-dark-gray-2, #252c32);
        font-family: $secondary-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.096px;
        margin: 0;
      }
    }
    .profiles_table_container {
      overflow-y: scroll;
      max-height: 400px;
      .profiles_title {
        display: flex;
        border-top: 1px solid #e5e9eb;
        border-bottom: 1px solid #e5e9eb;
        .name {
          width: 50%;
          padding: 0.5rem 0.8rem;
          p {
            margin: 0;
            color: var(--mid-gray-mid-gray-2, #6e7c87);
            font-family: $secondary-font;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.084px;
          }
        }
        .status {
          width: 50%;
          padding: 0.5rem 0.8rem;

          p {
            margin: 0;
            color: var(--mid-gray-mid-gray-2, #6e7c87);
            font-family: $secondary-font;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.084px;
          }
        }
      }
      .no_profile_container {
        text-align: center;
        padding: 1rem;
        p {
          margin: 0;
          color: var(--dark-gray-dark-gray-2, #252c32);
          font-family: $secondary-font;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.084px;
        }
      }
      .profile_details {
        display: flex;
        border-bottom: 1px solid #e5e9eb;
        cursor: pointer;
        &:hover {
          background-color: #f4f4f5;
        }
        .name {
          width: 50%;
          padding: 0.25rem 0.8rem;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          p {
            margin: 0;
            color: var(--dark-gray-dark-gray-2, #252c32);
            font-family: $secondary-font;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.084px;
          }
        }
        .status {
          width: 50%;
          padding: 0.25rem 0.8rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          p {
            margin: 0;
            color: #22c55e;
            font-family: $secondary-font;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.084px;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}

@include media(mobile) {
  .main_linkedin_profiles_container {
    .profile_not_selected_container {
      display: none;
    }
  }
  .profile_setting_container {
    padding: 2.3rem 1rem;
  }
}
