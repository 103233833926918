@import '../../../Styles/variables.scss';

.profile_setting_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 2.3rem 2rem;
  flex-wrap: wrap;
  position: relative;
  .paused_screen_container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(21 21 21 / 16%);
    z-index: 90;
    backdrop-filter: blur(5px);
    p {
      color: #18181b;
      font-family: 'Plus Jakarta Sans', sans-serif;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      margin: 0;
    }
  }
  .profile_details {
    h2 {
      color: #18181b;
      font-family: $primary-font;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      margin: 0;
      svg {
        width: 18px;
        height: 18px;
        cursor: pointer;
        margin-left: 0.5rem;
      }
    }
    h5 {
      color: #18181b;
      font-family: $primary-font;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 133.333% */
      margin: 0 0 0.4rem 0;
    }
  }
  .details {
    .linkedin_account,
    .business_page {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        margin: 0 2rem 0 0;
        color: var(--dark-gray-dark-gray-2, #252c32);
        font-family: $primary-font;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }
    .business_page {
      padding-top: 1rem;
      p {
        margin: 0 3.5rem 0 0;
      }
    }
  }
}

.main_campaign_details_container {
  margin-top: 1.3rem;
  .targets_container {
    margin-top: 20px;
    h2 {
      color: #18181b;
      font-family: $primary-font;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      margin: 0 0 10px 0;
    }
  }
  .filters_container {
    margin-top: 20px;
    h2 {
      color: #18181b;
      font-family: $primary-font;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      margin: 0 0 10px 0;
    }
  }
  .engagement_chart_container {
    height: 100%;
    border-radius: 10px;
    background: var(--colors-white-100, #fff);
    .engagement_header {
      padding: 1rem 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      flex-wrap: wrap;

      .button_container {
        button {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          color: #18181b;
          font-family: $primary-font;
          font-size: 11px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px;
          padding: 4px 9px 4px 4px;
        }
      }
      .dates_container {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        flex-wrap: wrap;
        p {
          margin: 0;
          color: var(--dark-gray-dark-gray-2, #252c32);
          font-family: $primary-font;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.084px;
          border-bottom: 1px solid #dde2e4;
          padding-bottom: 0.5rem;
          cursor: pointer;
          &:hover {
            font-weight: 700;
            border-color: #252c32;
          }
        }
        .active {
          font-weight: 700;
          border-color: #252c32;
        }
      }
      h5 {
        margin: 0;
        color: #18181b;
        font-family: $primary-font;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
    }
    .engagement_chart {
      padding-bottom: 1rem;
    }
  }
  .top_targets_container {
    border-radius: 10px;
    background: #fff;
    padding-bottom: 1rem;
    min-height: 160px;
    .header_details_container {
      padding: 0.8rem 1rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.5rem;
      justify-content: space-between;
      border-bottom: 1px solid #e5e9eb;
      h6 {
        margin: 0;
        color: #18181b;
        font-family: $primary-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
      p {
        margin: 0;
        color: #2563eb;
        font-family: $primary-font;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
        display: flex;
        align-items: center;
        gap: 0.4rem;
        cursor: pointer;
      }
    }
    .tags_container {
      padding: 0.8rem 1rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      .tag {
        width: fit-content;
        border-radius: 10px;
        background: #dcfce7;
        padding: 0.5rem;
        p {
          color: #14532d;
          font-family: $primary-font;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          margin: 0;
        }
      }
      .tags_no_data {
        color: #14532d;
        font-family: $primary-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: 1rem 0 0 0;
        text-align: center;
        width: 100%;
      }
    }
  }
  .least_engaged_targets {
    border-radius: 10px;
    background: #fff;
    padding-bottom: 1rem;
    min-height: 160px;
    .header_details_container {
      padding: 0.8rem 1rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.5rem;
      justify-content: space-between;
      border-bottom: 1px solid #e5e9eb;
      h6 {
        margin: 0;
        color: #18181b;
        font-family: $primary-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
      p {
        margin: 0;
        color: #2563eb;
        font-family: $primary-font;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
        display: flex;
        align-items: center;
        gap: 0.4rem;
        cursor: pointer;
      }
    }
    .tags_container {
      padding: 0.8rem 1rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      .tag {
        width: fit-content;
        border-radius: 10px;
        background: #fef9c3;
        padding: 0.5rem;
        p {
          color: #713f12;
          font-family: $primary-font;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          margin: 0;
        }
      }
      .tags_no_data {
        color: #713f12;
        font-family: $primary-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: 1rem 0 0 0;
        width: 100%;
        text-align: center;
      }
    }
  }
  .load_parameters_button_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    gap: 1rem;
    flex-wrap: wrap;
    .subscription_panel {
      width: 300px;
      .subscription_name {
        margin: 0;
        color: var(--dark-gray-dark-gray-2, #252c32);
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        flex-wrap: wrap;
        span {
          text-transform: capitalize;
          font-weight: 500;
        }
      }
    }
    .button_container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }
  .disabled_status_message {
    margin-top: 2rem;
    p {
      color: #ff7875;
      font-family: 'Plus Jakarta Sans', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin: 0;
    }
  }
  .tags_error {
    border: 1px solid #ff7875;
    border-radius: 6px;
  }
}

.delete_campaign {
  h6 {
    margin: 0;
    color: #18181b;
    font-family: $primary-font;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 2rem;
  }
  p {
    color: #18181b;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
    text-align: center;
  }
  .button_container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2rem;
  }
}

.modal_targets {
  h6 {
    margin: 0;
    color: #18181b;
    font-family: $primary-font;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
  }

  .tags_container {
    padding: 0.8rem 1rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 1rem;
    max-height: 300px;
    overflow-y: scroll;
    .top {
      width: fit-content;
      border-radius: 10px;
      background: #dcfce7;
      padding: 0.5rem;
      p {
        color: #14532d;
        font-family: $primary-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: 0;
      }
    }
    .least {
      width: fit-content;
      border-radius: 10px;
      background: #fef9c3;
      padding: 0.5rem;
      p {
        color: #713f12;
        font-family: $primary-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: 0;
      }
    }
  }
}

.update_subscription_container {
  h6 {
    margin: 0;
    color: #18181b;
    font-family: $primary-font;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
  }
  .subscription_selection {
    margin: 3rem 0 1rem 0;
    .space {
      margin: 8px;
      display: block;
    }
  }
  .button_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.custom_tags_container {
  .header_details_container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 10px 10px 0px 0px;
    background: var(--color-3, #fff);
    padding: 1.3rem 1.5rem;
    .title {
      h6 {
        margin: 0;
        color: var(--gray-900, #18181b);
        font-family: $primary-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
      p {
        margin: 0;
        color: var(--gray-500, #71717a);
        font-family: $primary-font;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
}

@include media(mobile) {
  .profile_setting_container {
    padding: 2.3rem 1rem;
    .details {
      .linkedin_account {
        flex-wrap: wrap;
        gap: 0.5rem;
      }
      .business_page {
        gap: 0.5rem;
        flex-wrap: wrap;
      }
    }
  }
  .main_campaign_details_container {
    .engagement_chart_container {
      .engagement_header {
        padding: 1rem;
      }
    }
  }
}
