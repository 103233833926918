@import '../../../Styles/variables.scss';

.custom_tags_container {
  .header_details_container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 10px 10px 0px 0px;
    background: var(--color-3, #fff);
    padding: 1.3rem 1.5rem;
    .title {
      h6 {
        margin: 0;
        color: var(--gray-900, #18181b);
        font-family: $primary-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
      p {
        margin: 0;
        color: var(--gray-500, #71717a);
        font-family: $primary-font;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }

    .check_location_container {
      display: flex;
      align-items: center;
      gap: 2rem;
      flex-wrap: wrap;
      .include,
      .exclude {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        opacity: 0.5;

        p {
          margin: 0;
          color: var(--dark-gray-dark-gray-2, #252c32);
          font-family: $primary-font;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: -0.084px;
        }
      }
      .active {
        opacity: 1;
      }
    }
  }

  .tags_container {
    padding: 1.2rem 1.5rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    border-radius: 0px 0px 10px 10px;
    border-top: 1px solid var(--gray-200, #e4e4e7);
    background: var(--color-3, #fff);
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  }
}

@include media(mobile) {
  .custom_tags_container {
    .header_details_container {
      padding: 1.3rem 1rem;
    }
    .tags_container {
      padding: 1.2rem 1rem;
    }
    .check_location_container {
      gap: 1rem !important;
    }
  }
}
